<template>
    <backoffice-layout :title="$t('booking', { id: booking.token })"> 
        <template #title-meta> 
            <v-tag v-if="booking.status" :variant="booking.status.variant">
                {{ $t(booking.status.name) }}
            </v-tag>
        </template>

        <template v-if="booking?.status?.id === 1" #header-actions>
            <div class="flex justify-end">
                <v-button v-confirm="$t('confirm.message', { action: $t('action.accept'), item: $t('booking') })" variant="success" @confirm="handleUpdate(2)">  
                    {{ $t('action.accept') }} 
                </v-button>

                <v-button v-confirm="$t('confirm.message', { action: $t('action.decline'), item: $t('booking') })" variant="danger" class="ml-2" @confirm="handleUpdate(3)">
                    {{ $t('action.decline') }} 
                </v-button>
            </div>
        </template>

        <article>
            <ul class="grid grid-cols-3 gap-6 <md:grid-cols-1">
                <li>
                    <v-card class="flex items-center p-2">
                        <v-icon class="bg-orange-100 text-orange-400 rounded-xl flex items-center justify-center w-10 h-10" name="schedule" />

                        <div class="ml-4">
                            <v-heading level="h6"> {{ $t('duration') }} </v-heading>

                            <span class="font-semibold text-md">
                                {{ booking.duration ? $dayjs().startOf('day').add(booking.duration, 'second').format('H[h]mm[min]') : '--' }}
                            </span>
                        </div>
                    </v-card>
                </li>

                <li>
                    <v-card class="flex items-center p-2">
                        <v-icon class="bg-blue-100 text-blue-400 rounded-xl flex items-center justify-center w-10 h-10" name="map" />

                        <div class="ml-4">
                            <v-heading level="h6"> {{ $t('distance') }} </v-heading>

                            <span class="font-semibold text-md">
                                {{ booking.distance ? `${(Math.ceil(booking.distance / 1000))}km` : '--' }}
                            </span>
                        </div>
                    </v-card>
                </li>

                <li>
                    <v-card class="flex items-center p-2">
                        <v-icon class="bg-purple-100 text-purple-400 rounded-xl flex items-center justify-center w-10 h-10" name="euro" />

                        <div class="ml-4">
                            <v-heading level="h6"> {{ $t('price') }} </v-heading>

                            <span class="font-semibold text-md"> {{ $fallback(booking.price, { type: 'price' }) }} </span>
                        </div>
                    </v-card>
                </li>
            </ul>
        </article>

        <hr class="my-4">

        <v-card :title="$tc('appointment')" class="mb-5">
            <ul class="grid auto-cols-auto gap-4">
                <li class="mb-3 col-span-4 <md:col-span-12">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.date_time') }} </v-heading>
                    {{ $fallback(booking.schedule_departure, { type: 'date' }) }}
                </li>

                <li class="mb-3 col-span-4 <md:col-span-12">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.pickup_address') }} </v-heading>
                    {{ $fallback(booking.pickup_address) }}
                </li>

                <li class="mb-3 col-span-4 <md:col-span-12">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.drop_off_address') }} </v-heading>
                    {{ $fallback(booking.dropoff_address) }}
                </li>

                <li class="mb-3 col-span-4 <md:col-span-12">
                    <v-heading level="h6" class="mb-1"> {{ $tc('label.waypoint', 2) }} </v-heading>

                    <ul v-if="booking.itinerary?.length > 2" class="list-disc pl-4">
                        <li v-for="(waypoint, idx) in booking.itinerary.slice(1, booking.itinerary?.length - 1)" :key="idx">
                            {{ waypoint }}
                        </li>
                    </ul>

                    <span v-else> -- </span>
                </li>

                <li class="mb-3 col-span-4 <md:col-span-12">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.nb_seat') }} </v-heading>
                    {{ $fallback($tc('nb_seat', { count: booking.nb_seats })) }}
                </li>

                <li class="mb-3 col-span-4 <md:col-span-12">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.flight_number') }} </v-heading>
                    {{ $fallback(booking.flight_number) }}
                </li>

                <li class="mb-3 col-span-12">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.comments') }} </v-heading>
                    {{ $fallback(booking.comments) }}
                </li>
            </ul>
        </v-card>

        <v-card :title="$t('contact_informations')">
            <ul class="grid grid-cols-3 gap-4 <md:grid-cols-1">
                <li class="mb-3">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.fullname') }} </v-heading>
                    {{ $fallback(booking.user?.fullname) }}
                </li>

                <li class="mb-3">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.mobile') }} </v-heading>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="$fallback(booking.user?.mobile, { type: 'tel' })"></span>
                </li>

                <li class="mb-3">
                    <v-heading level="h6" class="mb-1"> {{ $t('label.email') }} </v-heading>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="$fallback(booking.user?.email, { type: 'email' })"></span>
                </li>
            </ul>
        </v-card>
    </backoffice-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        openModal: false,
    }),

    computed: {
        ...mapGetters('admin_bookings', [
            'booking',
            'status'
        ]),
    },

    mounted () {
        this.getBooking(this.$route.params.id);
    },

    methods: {
        ...mapActions('admin_bookings', [
            'getBooking',
            'updateBooking',
        ]),

        handleUpdate (status_id) {
            this.booking.status_id = status_id;

            this.updateBooking(this.booking)
        }
    }
}
</script>
